import { gql, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import MetaTags from "react-meta-tags";
import TrackVisibility from "react-on-screen";
import NotFound from "../NotFound/component";
import { PageContext } from "./../../context/PageContext";
import StickyNavbar from "./../../include/NavbarSticky";
import { SectionCover } from "./SectionCover";
import { SectionLocation } from "./SectionLocation";
import { SectionRoom } from "./SectionRoom";

const tagManagerArgs = {
  dataLayer: {
    userId: "002",
    userProject: "Union-Group",
    page: "Restaurant",
  },
  dataLayerName: "PageDataLayer",
};

const GET_RESTAURANT = gql`
  query GetRestaurant($id: ID!) {
    brand(id: $id, idType: URI) {
      brands {
        cover {
          bookNowButton {
            text
            url
          }
          description
          image {
            altText
            sourceUrl
          }
          logo {
            altText
            sourceUrl
          }
        }
        information {
          branch {
            address
            fieldGroupName
            gmapsUrl
            name
            openingHours
            phoneNo
            whatsappNo
          }
          gmapsLocation {
            latitude
            longitude
          }
          name
        }
        middle {
          bannerImage {
            order
            image {
              altText
              title
              sourceUrl
            }
          }
          middleDescription
          orderOnlineButton {
            text
            url
          }
        }
        menu {
          ... on BrandsMenu {
            title
            id
            brandsMenu {
              cover {
                sourceUrl
                altText
              }
              logo {
                altText
                sourceUrl
              }
              tugMenu {
                locations {
                  locationName
                  sectionName
                  menu {
                    title
                    file {
                      mediaItemUrl
                      uri
                    }
                    url
                  }
                }
              }
            }
          }
        }
        houseRules {
          button
          title
          content
        }
      }
      seo {
        title
        metaDesc
        metaKeywords
      }
    }
  }
`;

const RestaurantLoad = ({ brand_id, match }) => {
  TagManager.dataLayer(tagManagerArgs);
  const linkActive = match?.params?.link;
  const { data, loading } = useQuery(GET_RESTAURANT, {
    variables: { id: linkActive },
  });
  const dataRestaurant = data?.brand?.brands;
  const dataSeo = data?.brand?.seo;
  const dataSectionRoom = {
    ...dataRestaurant?.middle,
    bannerImage: dataRestaurant?.middle?.bannerImage
      ?.map((item) => item)
      .sort((a, b) => a?.order - b?.order),
    menu: dataRestaurant?.menu?.length ? dataRestaurant?.menu[0] : {},
    houseRules: dataRestaurant?.houseRules ?? null,
  };
  let locationRestaurants = [];
  const [cond, setCond] = useState({
    load: true,
    cover: "",
    location: [],
    stickyNavbarImg: "",
    stickyNavbarAlt: "",
    stickyNavbarClassName: "",
    locationTitle: "",
    numPages: null,
    pageNumber: 1,
    metaDesc: "",
    metaTitle: "",
    metaKey: "",
  });
  let [bookNowRestaurant, setBookNowRestaurant] = useState(null);
  const {
    brand,
    common,
    sample,
    ico,
    restaurant,
    handleNavLoad,
    handleRestaurantLoad,
    handlePageLoad,
  } = useContext(PageContext);
  const images = { brand, common, sample, restaurant, ico };
  useEffect(() => {
    handlePageLoad("restaurant");
    if (dataRestaurant) {
      locationRestaurants = (
        dataRestaurant?.information?.branch ||
        dataRestaurant?.information?.location
      ).map((item) => ({
        place: item.name,
        address: item.address,
        link: item.gmapsUrl,
        openHours: item.openingHours,
        contactText: "Bookings & Enquiries",
        contact: item.phoneNo,
        contactWa: item.whatsappNo,
        mapsLocation: dataRestaurant?.information?.gmapsLocation,
      }));
    }
    handleNavLoad(true);
    setCond({
      cover: dataRestaurant?.cover?.image?.sourceUrl,
      stickyNavbarImg: dataRestaurant?.cover?.logo?.sourceUrl,
      stickyNavbarAlt: `${linkActive} icon`,
      stickyNavbarClassName: linkActive,
      locationTitle: dataRestaurant?.cover?.description,
      location: locationRestaurants,
      metaTitle: dataSeo?.title,
      metaDesc: dataSeo?.metaDesc,
      metaKey: dataSeo?.metaKeywords,
    });
    setBookNowRestaurant(
      (bookNowRestaurant = dataRestaurant?.cover?.bookNowButton)
    );
    handleRestaurantLoad(dataRestaurant?.cover?.bookNowButton);
  }, [dataRestaurant]);
  return (
    <>
      {data && !data?.brand && !loading ? (
        <>
          <NotFound />
        </>
      ) : (
        <>
          <MetaTags>
            <title>{cond.metaTitle}</title>
            <meta name="keywords" content={cond.metaKey} />
            <meta name="description" content={cond.metaDesc} />
            <meta property="og:title" content={cond.metaTitle} />
            <meta property="og:description" content={cond.metaDesc} />
            <meta property="og:image" content={images.ico["richlink.jpg"]} />
          </MetaTags>
          <SectionCover img={cond?.cover} />
          <StickyNavbar
            img={cond.stickyNavbarImg}
            alt={cond.stickyNavbarAlt}
            className={cond.stickyNavbarClassName}
            bookNowRestaurant={bookNowRestaurant}
            linkActive={linkActive}
          />
          <div className="restaurant">
            <TrackVisibility once offset={400} className="bg-white">
              <SectionLocation
                locationTitle={cond.locationTitle}
                linkActive={linkActive}
                location={cond.location}
              />
            </TrackVisibility>
            <TrackVisibility once offset={650}>
              <SectionRoom
                images={images}
                linkActive={linkActive}
                location={cond.location}
                branch={cond.metaKey}
                data={dataSectionRoom}
              />
            </TrackVisibility>
            {/* <TrackVisibility once offset={400}>
          <SectionWhatsOn
            images={images}
            linkActive={linkActive}
            whatson={cond.whatson}
            brand_id={brand_id}
          />
        </TrackVisibility> */}
            {/* <TrackVisibility once offset={500}>
          <SectionIg linkActive={linkActive} images={images} />
        </TrackVisibility> */}
            <a
              href={bookNowRestaurant?.url}
              target="_blank"
              className="btn btn-primary btn-book-now-mobile"
              style={{
                visibility: linkActive == "pizza-milano" ? "hidden" : "inherit",
              }}
            >
              {bookNowRestaurant?.text}
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default RestaurantLoad;
